body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  caret-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*! /////// Top Nav /////////////*/
/*? /////////////////////////*/
/*! ////////////   /////////////*/

/******  top nav items    *******/

/* not targeted top navbar item hover */
.inverted #top.item.top:hover {
  transition: all 0.1s ease-in-out;
  color: gray !important;
  background: radial-gradient(#ffffff53, hsla(0, 0%, 100%, 0));
}
.after #top.item.top:hover {
  color: gray;
  transition: all 0.2s ease-in-out;
  background: radial-gradient(#d2cecab5, hsla(0, 0%, 100%, 0));
}

.after #top.item.top.targeted {
  transition: all 0.2s ease-in-out;
  background: radial-gradient(#d2cecab5, hsla(0, 0%, 100%, 0));
  color: gray;
}

.after #top.item.top {
  transition: all 0.2s ease-in-out;
  color: rgb(69, 69, 69);
}

#topnav {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  transition: all 2s ease-in-out;
}

.ui.secondary.menu.after {
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  position: fixed;
  min-width: 100%;
  background-color: rgb(255, 255, 255);
  transform: scale(1.1);
  box-shadow: 4px 4px 8px;
}

/*////////////      /////////////*/

/*! ////////////   bottom nav   /////////////*/
/*? ////////////   bottom nav   /////////////*/
/*! ////////////   bottom nav   /////////////*/

#bottomnav {
  transition: all 0.2s ease-in-out;
  height: 0.75em;
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(194, 149, 83, 0.242);
}
#bottomnav:hover {
  transform: scale(1.1);
  height: 3.5em;
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 235, 205, 0.721);
  background: radial-gradient(circle at 50%, #fee5c0, transparent);
}

/*! /////// About /////////////*/
/*? /////////////////////////*/
/*! ////////////   /////////////*/

#profile-pic {
  margin: auto;
  object-fit: cover;
  height: 300px;
  width: 300px;
  background-color: rgb(58 33 33 / 12%);
  border-radius: 100%;
  padding: 0.5em;
}

.description {
  display: flex;
  align-content: center;
  justify-content: center;
}

.about-text {
  text-align: center;
  max-width: 75ch;
}

/*! /////// gen - body /////////////*/
/*? /////////////////////////*/
/*! ////////////   /////////////*/

.body-title {
  text-align: center;
}

.ui.raised.text.container.segment {
  margin-top: 20%;
  position: relative;
  min-width: 90vw;
  z-index: 10;
}

.ui.inverted.vertical.masthead.center.aligned.segment {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -35%;
  min-height: 90%;
  width: 100%;
  position: absolute;
  z-index: 6;
  transform: translate3d(0px, 0px, 0px);
  margin-bottom: 40%;
  background-color: rgba(198, 186, 172, 0.529);
}
#sub-projects {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40vw;
  min-height: 90%;
  max-width: 0%;
  position: absolute;
  margin-bottom: 40%;
}
#sub-resume {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
  height: 200vh;
  max-width: 0%;
  position: absolute;
  z-index: 6;
  margin-bottom: 40%;
}
#sub-contact {
  display: none;
  overflow: hidden;
  margin-top: -35%;
  min-height: 90%;
  max-width: 50%;
  position: absolute;
  right: 0;
  z-index: 6;
}

.header.title {
  color: rgb(255, 255, 255);
  position: relative;
  margin: auto;
  font-size: 6em;
}
.sub.header.title {
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: rgb(255, 255, 255);
  position: relative;
  margin: auto;
  margin-top: 10%;
  font-size: 6em;
  z-index: 7;
}

/*! /////// About /////////////*/
/*? /////////////////////////*/
/*! ////////////   /////////////*/

#profile-pic {
  margin: auto;
  object-fit: cover;
  height: 300px;
  width: 300px;
  background-color: rgb(58 33 33 / 12%);
  border-radius: 100%;
  padding: 0.5em;
}

/* main subtitles */
.body-title {
  color: transparent;
  text-align: center;
}

/*! /////// Resume /////////////*/
/*? /////////////////////////*/
/*! ////////////   /////////////*/

@media (min-width: 660px) {
  #resume-body-title {
    margin-bottom: -4em;
  }
}

.location {
  float: right;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.8em;
}

.content.title {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: -0.8em;
}
.content a {
  color: #000;
}

.project.title a {
  font-weight: bold;
  margin-right: 0.1em;
}

.header-title {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}
#header-links {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}
#header-address {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.job-description {
  display: flex;
  flex-direction: column;
}

/*!Projects Section*/
/*Projects Section*/
/*!Projects Section*/

#projects-title-test {
  color: #6c6f6f;
  position: absolute;
  top: -3em;
  /* left: 35vw; */
  width: 100%;
  font-size: clamp(3rem, 4vw, 2.25rem);
  text-align: center;
}

@media (min-width: 860px) {
  #projects-body-title {
    margin-top: 20vh;
    margin-bottom: -40vh;
  }
}
@media (min-width: 660px) {
  #projects-body-title {
    margin-top: 10vh;
    margin-bottom: -20vh;
  }
}
@media (max-width: 500px) {
  #projects-body-title {
    margin-bottom: 5vh;
  }
  #sub-projects {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50vh;
    min-height: 90%;
    max-width: 0%;
    position: absolute;
    margin-bottom: 40%;
  }
  span.dummydiv {
    margin-top: 5em;
  }
}

.dummydiv {
  position: absolute;
  margin-top: 3.5em;
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
  height: 100%;
  width: 90vw;
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
}

.proj-main {
  margin-top: -30vh;
  margin: auto;
  position: relative;
  width: 90vw;
  height: 35em;
  z-index: 10;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
}
ul.proj-bar {
  color: black;
  width: fit-content;
  align-items: stretch;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.proj-list {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.proj-list-item.focused {
  background-color: white;
  z-index: 13;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  border-bottom: none;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
}
.proj-list-item {
  padding: 1em;
  color: black;
  z-index: 9;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  background-color: rgba(24, 23, 23, 0.057);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
}

.tab-title {
  font-size: clamp(1rem, 4vw, 1.25rem);
}

.proj-holder {
  height: 100%;
  margin-top: -0.1em;
  border-top-right-radius: 0.3;
  border-bottom-right-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  z-index: 12;
  background-color: white;
  /* box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%); */
}
.inner-proj-holder {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1em;
  z-index: 100;
}

/*! Project Display */

@media (max-width: 590px) {
  div.square-image.with.text {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  div.square-image.with.text.brew-proj.second {
    display: flex;
    flex-direction: column-reverse;
  }
  div.square-image.with.text.brew-proj {
    margin-top: -1em;
  }
  div.square-image.with.text.last {
    flex-direction: column-reverse;
    margin-top: -2em;
  }
  .demo-image.floating {
    z-index: 15;
  }
  .square-image span.project-text {
    width: 100%;
    max-width: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .square-image span.project-text.to-text {
    margin-bottom: -3em;
  }
  .demo-image.large.nft {
    max-height: 300px;
    max-width: 300px;
    border-radius: 0.3em;
  }

  .vertical-image.with.text.floating .project-text {
    max-width: 15ch;
  }
}

.project-container {
  display: flex;
  flex-direction: column;
  max-width: 75ch;
}
.outer-project-container {
  caret-color: transparent;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.text-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.proj.article.area {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.project-text {
  /* max-width: 75ch; */
  max-width: 50ch;
  font-size: clamp(1rem, 4vw, 1.25rem);
}

.project-text.project-intro {
  max-width: 50ch;
  margin-top: 2em;
  margin-bottom: 2em;
}
.demo-image {
  max-height: 250px;
  max-width: 250px;
  border-radius: 0.3em;
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
}
.demo-image.large {
  max-height: 350px;
  max-width: 350px;
  border-radius: 0.3em;
}
.demo-image:hover {
  transition: all 0.4s ease-in-out;
  border-radius: 0.3em;
  transform: scale(1.3);
}
.demo-video {
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
}
.demo-video.nftgram {
  width: 100%;
  display: flex;
  justify-content: center;
}

.project-title {
  color: #000;
  width: 100%;
  text-align: center;
  margin: auto;
  margin-bottom: 2em;
  margin-top: 2em;
}

.project-title.nftgram {
  color: #000;
  width: 100%;
  text-align: center;
  margin: auto;
  margin-bottom: 2em;
  margin-top: 2em;
}

.horizontal-image.with.text {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
}
.vertical-image.with.text {
  width: 100%;
  margin-top: 2em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  justify-content: space-around;
}

.square-image.with.text {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-top: 2em;
  margin-bottom: 2em;
}
.demo-image.floating {
  z-index: 15;
}
.project-text.floating {
  max-width: 20ch;
}
.project-text.horizontal.nft {
  margin-bottom: 1em;
}

/*!Contact Section*/
/*Contact Section*/
/*!Contact Section*/

#contact-title-test {
  color: #6c6f6f;
  position: absolute;
  font-size: clamp(3rem, 4vw, 2.25rem);
  width: 100%;
  text-align: center;
  top: -2em;
  left: -0.4em;
}
#resume-title-test {
  color: #6c6f6f;
  position: absolute;
  font-size: clamp(3rem, 4vw, 2.25rem);
  width: 100%;
  text-align: center;
  top: -2em;
  left: -0.25em;
}
#about-title-test {
  color: #ffffff;
  position: absolute;
  font-size: clamp(3rem, 4vw, 2.25rem);
  width: 100%;
  text-align: center;
  top: -2em;
  left: -0.25em;
}

.contact-input{
  caret-color: black;
}
#form-input-message{
  height: 30vh;
}

/*!Footer Section*/
/*Footer Section*/
/*!Footer Section*/

.footer {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-content: center;
}
.footer-content {
  color: #6c6f6f;
  text-align: center;
  margin: auto;
}
.footer-address {
  color: #6c6f6f;
}
.footer-links a {
  color: #6c6f6f;
}
.footer-links a:hover {
  color: #343535;
}
